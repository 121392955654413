@use "../config/" as *;
.form {
    &-head {
        @extend %flex-wrap;
        @extend %flex-n-c;
    }
    &-group {
        @extend %my-2;
    }
    &-wrapper {
        @extend %p-2;
        @extend %w-100;
    }
    &-title {
        flex-basis: 100%;
        @extend %font-24-pb;
        @extend %mb-1;
        @extend %pure-white-900;
    }
    &-sub-title {
        @extend %primary-50;
        @extend %font-16-pr;
    }
    &-element {
        height: var(--input-height);
        border-radius: var(--half-radius) var(--half-radius) 0 0;
        @extend %px-4;
        @extend %relative;
        @extend %neutral-5-bg;
        input {
            @include placeholder(transparent);
            @extend %neutral-5-bg;
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus {
                -webkit-text-fill-color: clr(neutral-1000);
                -webkit-box-shadow: 0 0 0 40rem clr(neutral-50) inset;
            }
        }
        .form-label {
            @extend %transition;
            @extend %pos-y-center;
            @extend %font-14-pr;
        }
    }
    &-label {
        left: var(--space-4);
        pointer-events: none;
        font-weight: 500;
        width: calc(100% - var(--space-4));
        @extend %neutral-800;
        @extend %transition;
        @extend %pos-y-center;
    }
    &-input {
        border: 0;
        outline: 0;
        box-shadow: none;
        font-weight: 500;
        @include square(100%);
        @extend %neutral-800;
        @extend %font-14;
        &:placeholder-shown {
            ~ .form-label {
                font-weight: 500;
            }
        }
        &:not(:placeholder-shown) {
            @extend %pt-3;
            & ~ .form-label {
                top: 30%;
                @extend %secondary-800;
                @extend %font-12-pm;
            }
        }
    }
}
.varified {
    .form-input {
        @extend %pr-5;
    }
    .form-element::after {
        content: "";
        right: var(--space-4);
        @include icon(varified, 20);
        @extend %pos-y-center;
        @extend %success-900;
    }
}
.error {
    .form-element {
        border-bottom: .3rem solid clr(error-900);
    }
    .form-input:not(:placeholder-shown) {
        & ~ .form-label {
            @extend %error-900;
        }
    }
}
.mobile-number-wrapper {
    @extend %gap-1;
    @extend %flex;
    .country-flag {
        border-radius: 0;
        width: 2rem;
        height: 1.3rem;
        aspect-ratio: 2 / 1;
    }
    .form-element {
        @extend %w-100;
    }
    .form-select-box {
        width: var(--country-width);
        z-index: var(--z-select-box);
        height: var(--input-height);
        flex-shrink: 0;
        border-radius: var(--half-radius) var(--half-radius) 0 0;
        @extend %relative;
        @extend %pure-white-900-bg;
        button[type="button"]:disabled {
            @extend %transparent-bg;
        }
    }
    // country code dropdown also can be used in other dropdowns
    .selected-title {
        border-radius: var(--half-radius) var(--half-radius) 0 0;
        height: var(--input-height);
        @extend %neutral-5-bg;
        @extend %text-left;
        @extend %w-100;
        @extend %flex-n-c;
        @extend %px-4;
        @extend %pt-4;
        .country-flag {
            @extend %mr-1;
        }
        &::after {
            right: var(--space-4);
            @include icon(chevron-down, 12);
            @extend %absolute;
            @extend %transition;
        }
        &[aria-expanded=true] {
            &::after {
                transform: rotate(180deg);
            }
            & ~ .select-list {
                @include dropdown(open);
            }
        }
        > .btn-text {
            width: 4rem;
            flex-shrink: 0;
            @extend %font-14-pm;
        }
    }
    .select-label {
        @include position(var(--space-2), null, null, var(--space-4), absolute);
        @extend %secondary-800;
        @extend %font-12-pr;
    }
    .select-list {
        position: absolute;
        top: 100%;
        left: 0;
        list-style: none;
        max-height: 15rem;
        width: 20rem;
        margin-top: .2rem;
        z-index: var(--z-form-dropdown);
        @include dropdown(close);
        @include custom-scroll();
        @extend %pure-white-900-bg;
        @extend %y-auto;
        @extend %px-2;
    }
    .list-item {
        @extend %flex;
        @extend %gap-2;
        @extend %py-2;
        @extend %w-100;
        &.active {
            &::before {
                order: 1;
                border: .1rem solid;
                @include square(2rem);
                @include icon(checkmark);
                @extend %flex-c-c;
                @extend %ml-auto;
                @extend %circle-radius;
            }
            .btn-text {
                font-weight: 700;
            }
        }
        .btn-text {
            flex: 1;
            @extend %text-left;
            @extend %font-14-pm;
        }
    }
}
.dropdown {
    @include flex-config(flex, column, center, flex-start);
    > button {
        @extend %flex-sb-n;
        @extend %w-100;
        &::after {
            right: var(--space-4);
            @include icon(chevron-down, 16);
            @extend %neutral-800;
            @extend %transition;
            @extend %pos-y-center;
        }
        &[aria-expanded="true"] {
            @extend %text-left;
            &::after {
                transform: rotate(180deg);
            }
            & ~ .form-dropdown {
                @include dropdown(open);
            }
        }
    }
    .form-label {
        font-size: 1.2rem;
        position: unset;
        translate: unset;
        @extend %secondary-800;
    }
    .form-dropdown {
        z-index: var(--z-form-dropdown);
        max-height: 16rem;
        overflow-y: auto;
        @include dropdown(close);
        @include position(5rem, null, null, 0);
        @extend %mt-1;
        @extend %w-100;
        @extend %pure-white-900-bg;
        .sub-ul {
            @extend %flex-column-n-n;
            @extend %w-100;
            @extend %hidden;
        }
        .list-item {
            @extend %w-100;
            @extend %relative;
            .btn-list {
                @extend %flex-n-c;
            }
            button {
                padding: 1.3rem var(--space-4);
                @extend %w-100;
                .btn-text {
                    @extend %font-14-pm;
                    @extend %neutral-900;
                }
            }
        }
    }
}
.element-wrapper {
    @extend %flex-sb-n;
    @extend %mb-3;
    .control-label {
        @extend %primary-50;
        @extend %font-14-pr;
    }
}
.error-msg {
    @extend %font-10;
    @extend %mt-1;
    @extend %px-4;
    @extend %error-900;
}
.seperator {
    border-width: 0.1rem 0 0 0;
    @extend %my-3;
    @extend %secondary-50-2;
}
.additional-options {
    @extend %mt-4;
    .question-title {
        @extend %pure-white-900;
        @extend %font-15-pr;
        @extend %mb-2;
    }
    .text {
        @extend %font-12;
        @extend %primary-50;
        @extend %mb-4;
    }
    .option-list {
        @extend %flex;
        @extend %flex-wrap;
        @extend %gap-3;
    }
    .option-text {
        @extend %d-block;
        @extend %font-12-pr;
        @extend %p-2-3;
        @extend %neutral-200-bg;
        @extend %half-radius;
    }
}
.form-otp-head {
    @extend %flex;
    @extend %flex-wrap;
    @extend %mb-3;
    .title {
        @extend %font-20-pr;
        @extend %pure-white-900;
        @extend %mb-2;
    }
    .text {
        flex-basis: 100%;
        @extend %w-100;
        @extend %font-10-pr;
        @extend %primary-50;
    }
}
.btn-skip {
    flex-basis: 100%;
    text-decoration: underline;
    @extend %text-right;
    @extend %font-12-pr;
    @extend %pure-white-900;
    @extend %capitalize;
    @extend %d-block;
    @extend %ml-auto;
    @extend %my-2;
}
.otp-wrapper {
    .form {
        &-element,
        &-input {
            @extend %half-radius;
        }
        &-input {
            @extend %p-0;
            @extend %text-center;
            @extend %font-18;
            @extend %neutral-900;
        }
        &-element {
            height: 4rem;
            width: 4rem;
            flex-shrink: 0;
            @extend %px-0;
            &::after {
                @extend %d-none;
            }
        }
    }
    .otp {
        &-wrap {
            @extend %flex-fs-c;
            @extend %gap-2;
            @extend %mb-3;
            &.success {
                .form-element {
                    border: .1rem solid var(--success-900);
                }
                .check::after {
                    @include icon(varified, 22);
                    @extend %success-900;
                }
            }
            &.error {
                .form-element {
                    border: .1rem solid var(--accent-900);
                }
                .check::after {
                    @include icon(circle-close, 20);
                    @extend %error-900;
                }
            }
        }
    }
    .resend-otp {
        flex-wrap: wrap;
        @extend %flex;
        @extend %mb-3;
        @extend %gap-2;
        .text {
            @extend %pure-white-900;
            @extend %font-12-pr;
        }
        .otp {
            @extend %font-12-pr;
            @extend %warning-900;
            @extend %ml-2;
        }
    }
    .timer {
        flex-basis: 100%;
        @extend %primary-50;
        @extend %font-12-pr;
    }
}
.checkbox-container {
    @extend %flex-n-fs;
    @extend %gap-2;
    input[type=checkbox] {
        @extend %d-none;
        &:checked ~ .checkmark {
            border-color: clr(tertiary-600);
            &::after {
                @include icon(checkmark, 11);
                @extend %tertiary-600;
            }
        }
    }
    .text {
        user-select: none;
        @extend %font-12;
        @extend %pure-white-900;
    }
    .link-text {
        @extend %tertiary-600;
    }
}
.checkmark {
    width: 1.6rem;
    height: 1.6rem;
    flex-shrink: 0;
    border-radius: .3rem;
    margin-top: .3rem;
    border: .2rem solid var(--pure-white-900);
    @extend %flex-c-c;
}
.disclaimer {
    @extend %mb-3;
}
.form-radio {
    .form-label {
        position: unset;
        transition: unset;
        @extend %font-12-pr;
        @extend %pure-white-900;
    }
}
.form-update {
    .form-label {
        line-height: 1.2rem;
        @extend %font-10-pr;
    }
    span {
        font-weight: 500;
    }
}
.radio-box-wrap {
    height: 4rem;
    @extend %flex-fs-c;
    .radio-box {
        @extend %relative;
        @extend %mr-4;
        .radio-container {
            cursor: pointer;
            user-select: none;
            margin-bottom: 0;
            @extend %relative;
            @extend %pl-5;
            @extend %font-12-pm;
            @extend %flex;
            @extend %pure-white-900;
            input {
                cursor: pointer;
                opacity: 0;
                left: 0;
                @extend %pos-y-center;
                @extend %d-none;
                &:checked {
                    ~ .checkmark {
                        border: 0.2rem solid clr(tertiary-800);
                        &:after {
                            display: block;
                        }
                    }
                }
            }
            .checkmark {
                height: 2rem;
                width: 2rem;
                border: 0.2rem solid clr(pure-white-900);
                left: 0;
                @extend %mt-0;
                @extend %pos-y-center;
                @extend %circle-radius;
                &:after {
                    content: "";
                    display: none;
                    width: 1rem;
                    aspect-ratio: 1/1;
                    @extend %circle-radius;
                    @extend %pos-center;
                    @extend %tertiary-800-bg;
                }
            }
        }
    }
}
.btn-fill {
    @extend %uppercase;
}
@include mq(col-lg) {
    .form-otp-head {
        .text {
            font-size: 1.2rem;
        }
    }
    .error-msg {
        font-size: 1.2rem;
    }
    .otp-wrapper {
        display: flex;
        flex-wrap: wrap;
        .otp-input-wrapper,
        .resend-otp {
            flex-basis: 100%;
        }
        .form {
            &-element {
                height: 5rem;
                width: 5rem;
            }
        }
    }
    .btn-skip {
        flex-basis: auto;
    }
    .disclaimer {
        padding-top: var(--space-2);
    }
}