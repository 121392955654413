@use "../config/" as *;
.waf-login {
    height: calc(var(--window-inner-height) - var(--header-height));
    @include background(clr(primary-900), "pattern/login-bg.png", top/cover);
    .form-area {
        height: calc(var(--window-inner-height) - var(--header-height));
        background: linear-gradient(180deg, rgba(0, 10, 68, 0.64) 68.48%, rgba(0, 10, 68, 0) 100%);
        @extend %full-radius;
        @extend %py-6;
        @extend %flex-c-n;
    }
    .form-wrapper {
        @extend %flex-column;
    }
    .btn-fill {
        @extend %btn-fill;
    }
    .varify-btn {
        width: 60%;
        @extend %mb-3;
        button {
            @extend %uppercase;
        }
    }
    .send-otp-btn {
        @extend %my-2;
    }
}
@include mq(col-lg) {
    .waf-login {
        padding: 0;
        @include background(clr(primary-900), "pattern/login-bg-web.png", top/cover);
        .form-area {
            width: 50%;
            margin-left: auto;
            padding: var(--space-6);
        }
        .form-wrapper {
            overflow-y: auto;
            height: 100%;
            @include custom-scroll();
        }
        .send-otp-btn {
            width: 40%;
        }
        .varify-btn {
            width: 40%;
        }
    }
}